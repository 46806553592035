import Vue from 'vue';
import StudioPageBase from '@/support/studioPageBase';
import { Component, Watch } from 'vue-property-decorator';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import PlayerUtil from '@/support/playerUtil';
import Util from '@/support/utility';
import Browser from '@/support/browser';
import { ServerDataModule } from '@/store/serverData';

@Component
export default class AwaitingApprovalComponent extends StudioPageBase {
    options = {
    };

    get serverData() {
        return ServerDataModule.serverData;
    }

    model = Globals.NewRequestAccess;

    loading = true;

    created() {
        super.created('awaitingApproval', true);

        Debug.setDebugModule('AwaitingApproval', this);

        this.alias = Browser.getRouteParam(this.$route, 'studio', null);

        this.model.studio = this.alias;
        this.model.email = this.serverData.email;
        this.model.fullName = this.serverData.fullName;

        this.getRequestAccessData();

        this.checkStudioAccess(this.alias);

        setInterval(() => {
            this.checkStudioAccess(this.alias);
        }, 5 * 60 * 1000);
    }

    mounted() {
        super.mounted();

        Debug.log("awaitingApproval mounted");
    }

    protected onLoaded(loaded: boolean) {
        super.onLoaded(loaded);
    }

    async getRequestAccessData() {
        try {
            let response = await PlayerApi.requestAccessData(this.alias);
            Object.assign(this.model, response);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('awaitingApproval - getRequestAccessData Failed: ' + message);

            PlayerUtil.redirectToError(this.$router);
        } finally {
            this.loading = false;
        }
    }

    testShowToast(message) {
        Util.showToast(message || 'Missing message');
    }
}
